/**
 * Function to check if in case of managed inventory is allowed
 * whether current quantity exceeds available quantity
 * if managed inventory is false, allow without checks
 * @param {*} stockItem
 * @return {boolean} boolean
 */
export const checkForInventoryExceeds = (stockItem) => {
  if (
    !(
      stockItem &&
      stockItem?.managed_inventory &&
      stockItem?.available_quantity &&
      stockItem?.quantity
    )
  ) {
    return true;
  }

  return stockItem.quantity < stockItem.available_quantity;
};
