/**
 * Helper function which returns the sequenced
 * array with the help for variants for QuickViewModal (QVM)
 * @param {object} product
 * @param {number} selectedVariantId
 * @return {array}
 */

export const getProductImagesForQVM = (product, selectedVariantId) => {
  let output = [];
  product = JSON.parse(JSON.stringify(product));

  if (product?.variants?.length && selectedVariantId > -1) {
    const selectedVariant = product?.variants.find(
      (item) => item?.variant_id === selectedVariantId
    );
    const selectedVariantImg = selectedVariant?.images;

    if (product?.images) {
      output = selectedVariantImg
        ? [...selectedVariantImg, ...product.images]
        : product?.images;
    } else output = selectedVariantImg;
  } else {
    output = product?.images;
  }

  if (output === null || (Array.isArray(output) && !output.length)) {
    output = [{ image_url: product?.image_url, thumbnail_url: product?.thumbnail_url }];
  }

  return output;
};
