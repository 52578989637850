import React, { useEffect, useState } from 'react';
import Modal from '@/components/Common/Modal';
import VariantRadioGroup from '@/components/VariantRadioGroup';
import { Button, CartButton } from '@/components/DesignSystem/AtomicComponents';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  QuickViewCarouselMobile,
  QuickViewDesktopImageComponent,
} from './QuickViewImageComponent';
import {
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  saveDetailedItemById,
  savePDPData,
  saveProductLoaderId,
  setChooseAndRepeatModalVisibility,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
  verifyBuyerForItem,
  getCustomerReviewSettings,
  setUnavailableQuantityAlertState,
} from 'src/redux/actions';
import { MinusIcon } from '@/assets/svgExports/MinusIcon';
import { QVCloseIcon } from '@/assets/svgExports/QVCloseIcon';
import { NoStoreInfoIcon } from '@/assets/svgExports/NoStoreInfoIcon';
import { PlusIcon } from '@/assets/svgExports/PlusIcon';
import { isNotifyProductEnabled } from '@/utils/isNotifyProductEnabled';
import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { isStoreGstExcluded, isStoreGstIncluded } from '@/utils/storeGSTStatus';
import { checkForLimitedQuantity } from '@/utils/checkForLimitedQuantity';
import { getItemAmountForPDP } from '@/utils/getItemAmountForPDP';
import DetailedDescription from '@/components/Common/DetailedDescription';
import { InteractionComponent } from '../AtomicComponents/CartButton/CartButton.styles';
import { getRoute, PATH } from '@/utils/routes';
import { useRouter } from 'next/router';
import { roundnessCalculator } from '../AtomicComponents/util';
import { toastNotifyInfo } from '@/components/Common/Toast';
import CheckItemDelivery from '@/components/CheckItemDelivery/CheckItemDelivery';
import RatingBox from '../../RatingBox';
import ReviewsSection from '../../ReviewsSection';
import { MAX_MOBILE_WIDTH, STORE_CUSTOMIZATION } from '../../../utils/constants';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import NotifyProduct from '@/components/NotifyProduct';
import { mutateItemObjectWithCartData } from '@/utils/mutateItemObjectWithCartData';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';
import { getDiscountPercentage } from '@/utils/getDiscountPercentage';
import { getProductImagesForQVM } from 'src/utils/getProductImagesForQVM';
import { deviceWidth, OSName } from '@/utils/deviceWidth';
import { checkForInventoryExceeds } from '@/utils/checkForInventoryExceeds';
import { getStoreType } from '@/utils/getStoreType';
import { checkProductPersonalizationExist } from '../../../containers/ProductDescription/Utils/common';
import { useCartHandlers } from '../../../hooks/useCartHandler';
import { IS_CLIENT } from '../../../utils/checkRenderEnv';
import AllProductPagePromos from 'src/components/CouponsAndVouchers/AllProductPagePromos';
import { PROMO_APPLICABILITY_TYPES } from 'src/constants/promos';
import SatisfiedBogoPromos from 'src/components/CouponsAndVouchers/SatisfiedBogoPromos';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import QuntityUnavailableAlert from 'src/components/QuntityUnavailableAlert';
import ProductScheduler from 'src/components/ProductScheduler';
import { itemIndexFromCart } from 'src/utils/itemIndexFromCart';
import useItemScheduler from 'src/hooks/useItemScheduler';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';

const QuickViewModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { userData, reviewSettings, storeInfo, cartData, pdpData, scheduleInfo } =
    useSelector((state) => ({
      userData: state.userReducer,
      reviewSettings: state.catalogReducer.reviewSettings,
      storeInfo: state.storeReducer.store,
      cartData: state.cartReducer,
      pdpData: state.catalogReducer.pdpData,
      scheduleInfo: state.commonReducer.scheduleInfo,
    }));
  const schedualerConstraints = pdpData?.product?.item_schedule;

  const isB2bStore = getStoreType(storeInfo) === STORE_CUSTOMIZATION.B2B;
  const showDetailedDescription = pdpData?.product?.description_detail;
  const [state, setState] = useState({
    itemCartIdx: -1,
    discountPercent: 0,
    activeVariant: -1,
    cost: {
      discounted_price: 0,
      price: 0,
    },
    addItemsPossible: true, // used to check if add button should be disabled or not
    selectedVariantId: -1, // id of selected variant from variant list
  });
  const [verifedBuyer, setVerifiedBuyer] = useState(false);
  const { crrFeatureStatus, notifyMeFeatureStatus } = useFeatureLocks();
  const isCRRFeatureUnlocked = !crrFeatureStatus?.isLocked;
  const isNotifyMeFeatureVisible = notifyMeFeatureStatus?.isVisible;
  const { storePhone } = useStoreContactDetails();
  const [showSchedulerError, setShowSchedulerError] = useState(false);

  const {
    handleSetProductData,
    onPersonalizeProductClick,
    handleSetProductCustomizationCount,
    handleIncreamentClick,
    handleDecreamentClick,
  } = useCartHandlers();

  const { setScheduleData, changeSchedulingInfoAndSetCart, addScheduleInfoToCart } =
    useItemScheduler();

  const itemIndexInCart = itemIndexFromCart(cartData, pdpData?.product);

  useEffect(() => {
    if (cartData?.items?.[itemIndexInCart]?.schedule_info) {
      setScheduleData(cartData?.items?.[itemIndexInCart]?.schedule_info);
    } else {
      setScheduleData(null);
    }
  }, [cartData]);

  useEffect(() => {
    if (pdpData?.visible) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    };
  }, [pdpData]);

  useEffect(() => {
    if (pdpData.visible) {
      setState((state) => ({
        ...state,
        cost: getItemAmountForPDP(
          pdpData.product,
          pdpData?.product?.variants_count > 0 ? 0 : -1
        ),
      }));
      dispatch(
        fetchCatalogItemById({
          store_id: storeInfo?.store_id,
          item_id: pdpData?.product?.id,
          src: 'quick_view_modal',
        })
      );
      if (userData?.isLogin) {
        const data = {
          phone: userData?.data?.phone || '',
          item_id: pdpData?.product?.id,
          store_id: storeInfo?.store_id,
        };
        if (data.phone === '') {
          data.user_id = userData?.data?.user_id || '';
        }
        dispatch(verifyBuyerForItem(data, (data) => setVerifiedBuyer(data)));
      }
      if (storeInfo?.store_id) {
        dispatch(getCustomerReviewSettings(storeInfo.store_id));
      }
    } else {
      setState((state) => ({
        ...state,
        activeVariant: -1,
        cost: {
          discounted_price: 0,
          price: 0,
        },
      }));
    }
  }, [pdpData.visible]);

  useEffect(() => {
    const itemCartIdx = cartData.items.findIndex(
      (el) => (el.id || el.item_id) === pdpData?.product?.id
    );
    const discountPercent = getDiscountPercentage(
      state?.cost?.price,
      state?.cost?.discounted_price
    );
    setState((state) => ({
      ...state,
      itemCartIdx,
      discountPercent,
    }));
  }, [pdpData, cartData]);

  useEffect(() => {
    if (pdpData?.product?.variants) {
      /** find the first active variant index */
      let activeVariantIndex = -1;

      if (pdpData?.product?.variants)
        activeVariantIndex = pdpData?.product?.variants?.findIndex(
          (el) => el.available === 1 && el.status !== 2
        );

      setState((state) => ({
        ...state,
        activeVariant: activeVariantIndex,
        selectedVariantId:
          activeVariantIndex > -1
            ? pdpData?.product?.variants?.[activeVariantIndex]?.variant_id
            : -1,
      }));
    }
    if (pdpData?.product?.product_personalization) {
      handleSetProductCustomizationCount(pdpData?.product);
      handleSetProductData(pdpData?.product);
    }
  }, [pdpData.product]);

  useEffect(() => {
    if (state.activeVariant > -1 && pdpData?.product?.variants) {
      setState((state) => ({
        ...state,
        cost: getItemAmountForPDP(pdpData.product, state.activeVariant),
      }));
    }
  }, [state.activeVariant]);

  // call following useEffect on every cartData change
  useEffect(() => {
    if (state.itemCartIdx !== -1) {
      // on cartData state change, allow addition of quantity possible
      setState((state) => ({
        ...state,
        addItemsPossible: true,
      }));
      if (state.selectedVariantId !== -1) {
        // if variant item
        const variantForStock = selectedVariantFromVariantItem();

        if (variantForStock && Object.keys(variantForStock).length) {
          // if variant data exits
          setState((state) => ({
            ...state,
            addItemsPossible: checkForInventoryExceeds(variantForStock),
          }));
        }
      } else {
        // if non variant item
        const itemForStock = cartData?.items?.[state.itemCartIdx];
        if (itemForStock) {
          setState((state) => ({
            ...state,
            addItemsPossible: checkForInventoryExceeds(itemForStock),
          }));
        }
      }
    }
  }, [cartData]);

  const numberClickFunc = (e) => {
    if (!(OSName && (OSName === 'Android' || OSName === 'iPhone') && deviceWidth < 766)) {
      e.preventDefault();
    }
  };

  const onClose = () => {
    dispatch(savePDPData({ visible: false, product: {} }));
  };

  const handleCartUpdate = (type, count = null) => {
    /** Check if variant present in this item */
    if (pdpData?.product?.variants_count > 0) {
      dispatch(saveProductLoaderId(pdpData?.product.id));
      dispatch(saveDetailedItemById(pdpData?.product));
      /** If variant not pre selected */
      if (state.activeVariant === -1) {
        onClose();
        switch (type) {
          case 'add':
            dispatch(setVariantModalVisibility(true));
            break;
          case 'inc':
            dispatch(setChooseAndRepeatModalVisibility(true));
            break;
          case 'dec':
            dispatch(setSelectedVariantModalVisibility(true));
            break;
        }
      } else {
        /** If variants are preselected */
        let newCart = [];
        type = type === 'add' || type === 'inc' ? 'inc' : type;
        newCart = mutateVariantItemWithCartData(
          type,
          pdpData?.product,
          pdpData?.product?.variants?.[state.activeVariant],
          cartData,
          null,
          null,
          null,
          count
        );
        addScheduleInfoToCart(newCart, pdpData?.product, scheduleInfo);
        dispatch(saveCart(newCart));
      }
    } else {
      const newState = mutateItemObjectWithCartData(
        type,
        pdpData.product,
        cartData,
        null,
        null,
        null,
        count
      );
      addScheduleInfoToCart(newState, pdpData?.product, scheduleInfo);
      dispatch(saveCart(newState));
    }

    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeInfo?.store_id,
          domain: storeInfo?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
  };

  const onBuyNowClickQuickView = () => {
    if (schedualerConstraints && !scheduleInfo) {
      setShowSchedulerError(true);
      return;
    }
    if (itemIndexInCart === -1) {
      handleCartUpdate('add');
    }
    router.push(getRoute(PATH.CART, storeInfo?.store_info?.domain));
  };

  const selectedVariantFromVariantItem = () =>
    cartData?.items?.[itemIndexInCart]?.variants_selected.find(
      (variant) => variant.variant_id === state.selectedVariantId
    );

  const returnItemQuantity = () => {
    if (itemIndexInCart >= 0) {
      if (cartData?.items?.[itemIndexInCart]?.variants_selected?.length) {
        const selectedVariant = selectedVariantFromVariantItem();
        return selectedVariant?.quantity ?? 0;
      } else {
        return cartData?.items?.[itemIndexInCart]?.quantity;
      }
    }
    return 0;
  };

  const onVariantSelect = (selectedInd, item) => {
    setState((state) => ({
      ...state,
      activeVariant: selectedInd,
      selectedVariantId: pdpData?.product?.variants?.[selectedInd]?.variant_id,
    }));
  };

  function openProductPersonalizationPopUp() {
    IS_CLIENT && localStorage.setItem('activeVariantId', state.selectedVariantId);
    handleSetProductData(pdpData?.product);
    handleSetProductCustomizationCount(pdpData?.product);
    onPersonalizeProductClick();
  }

  function handleCartAction(type) {
    if (
      checkProductPersonalizationExist(
        storeInfo?.services?.store_type_flag,
        pdpData?.product?.product_personalization
      )
    ) {
      IS_CLIENT && localStorage.setItem('activeVariantId', state.selectedVariantId);
      type === 'inc'
        ? handleIncreamentClick(type, pdpData?.product)
        : handleDecreamentClick(type, pdpData?.product);
    } else {
      handleCartUpdate(type);
    }
  }

  const renderMobileView = (children) => {
    return (
      <Modal
        visible={pdpData?.visible}
        className="bottom-modal zIndex99"
        animation={'slideUp'}
        onClose={onClose}
        height="auto"
        customStyles={{
          padding: 0,
        }}
        customMaskStyles={{
          background: 'rgba(0, 0, 0, 0.9)',
        }}
      >
        <span className="QuickViewModalContainer__close" onClick={onClose}>
          <QVCloseIcon size={38} />
        </span>
        {children}
      </Modal>
    );
  };

  const renderDesktopView = (children) => {
    return (
      <aside className="QuickViewModalContainer">
        <span className="QuickViewModalContainer__close" onClick={onClose}>
          <QVCloseIcon size={30} />
        </span>
        {children}
      </aside>
    );
  };

  const LeftCartButton = () => (
    <InteractionComponent
      position="left:14px;"
      width="25px"
      onClick={(e) => {
        e.stopPropagation();
        handleCartAction('dec');
      }}
    >
      <MinusIcon />
    </InteractionComponent>
  );
  const RightCartButton = () => (
    <InteractionComponent
      position="right:14px;"
      width="25px"
      onClick={(e) => {
        if (state.addItemsPossible) {
          e.stopPropagation();
          handleCartAction('inc');
        } else {
          toastNotifyInfo('No more units available for purchase');
        }
      }}
    >
      <span className={state.addItemsPossible ? 'o-100' : 'o-20'}>
        <PlusIcon />
      </span>
    </InteractionComponent>
  );

  const renderProductPersonalizationCTA = () => {
    return (
      <span className="!tw-mx-0 tw-w-full md:tw-w-[78%] md:tw-max-w-[550px]">
        <button
          className="tw-mx-auto tw-my-0 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-[4px] tw-border-[0px] tw-bg-black tw-py-[0.625rem] tw-text-[0.875rem] tw-font-medium tw-text-[#fff] tw-outline-0"
          onClick={() => {
            if (schedualerConstraints && !scheduleInfo) {
              setShowSchedulerError(true);
              return;
            }
            openProductPersonalizationPopUp();
          }}
          style={{
            backgroundColor: `${
              storeInfo?.theme ? storeInfo?.theme?.colors?.primary_color : '#EA5151'
            }`,
            borderRadius: `${
              storeInfo?.theme
                ? `${roundnessCalculator(storeInfo?.theme?.roundness)}`
                : '8px'
            }`,
            opacity: '1',
          }}
        >
          {/* Change condition ! */}
          {storeInfo?.theme?.cta_config?.pdp_personalized_cta ||
            'Personalise and buy'}{' '}
        </button>
      </span>
    );
  };

  const onChange = (type = 'add', count = null) => {
    if (
      checkProductPersonalizationExist(
        storeInfo?.services?.store_type_flag,
        pdpData?.product?.product_personalization
      )
    ) {
      openProductPersonalizationPopUp();
    } else {
      handleCartUpdate(type, count);
    }
  };

  const handleItemChange = (type, count = null, reset = null) => {
    if (schedualerConstraints && !scheduleInfo) {
      setShowSchedulerError(true);
      return;
    }

    const selectedProductInfo =
      state.activeVariant !== -1
        ? pdpData?.product?.variants?.[state.activeVariant]
        : pdpData?.product;
    if (
      count &&
      selectedProductInfo?.managed_inventory &&
      selectedProductInfo?.available_quantity < count
    ) {
      dispatch(
        setUnavailableQuantityAlertState({
          show: true,
          availableQuantity: selectedProductInfo?.available_quantity,
          onConfirm: () => onChange(type, selectedProductInfo?.available_quantity),
          onCancel: reset,
        })
      );
    } else {
      onChange(type, count);
    }
  };

  const _renderAddToCartBuyAgainCta = () => {
    if (storeInfo?.services?.store_flag === 0) {
      return null;
    }

    if (
      checkProductPersonalizationExist(
        storeInfo?.services?.store_type_flag,
        pdpData?.product?.product_personalization
      ) &&
      itemIndexInCart === -1
    ) {
      return renderProductPersonalizationCTA();
    }

    return (
      storeInfo?.services?.store_flag !== 0 && (
        <>
          <span className="QuickViewModal__bottom--btn-container">
            <CartButton
              allowCustomInput={!pdpData?.product?.product_personalization}
              leftButton={<LeftCartButton />}
              rightButton={<RightCartButton />}
              label={
                checkProductPersonalizationExist(
                  storeInfo?.services?.store_type_flag,
                  pdpData?.product?.product_personalization
                )
                  ? storeInfo?.theme?.cta_config?.pdp_personalized_cta ||
                    'Personalise and buy'
                  : 'Add to cart'
              }
              primaryColor={storeInfo?.theme?.colors?.primary_color}
              roundness={storeInfo?.theme?.roundness}
              onChange={handleItemChange}
              fontWeight="400"
              fontSize="14"
              py="10px"
              centered={true}
              quantity={returnItemQuantity()}
              style={{
                width: 180,
                justifyContent: 'center',
              }}
            />
          </span>
          <span className="QuickViewModal__bottom--btn-container">
            {state.itemCartIdx === -1 ? (
              <Button
                display="inline-block"
                label={storeInfo?.theme?.cta_config?.pdp_cta || 'Buy Now'}
                backgroundColor={storeInfo?.theme?.colors?.primary_color}
                roundness={storeInfo?.theme?.roundness}
                fontWeight="400"
                py="10px"
                onClick={onBuyNowClickQuickView}
                style={{
                  width: 180,
                }}
              />
            ) : (
              <div
                className="go-to-cart__btn"
                style={{
                  borderRadius: `${roundnessCalculator(storeInfo?.theme?.roundness)}`,
                }}
                onClick={() => {
                  onClose();
                  setTimeout(() => router.push(getRoute(PATH.CART, storeInfo?.domain)));
                }}
              >
                Go to Cart
              </div>
            )}
          </span>
        </>
      )
    );
  };

  const _renderImagesBox = () => {
    const imagesArray = getProductImagesForQVM(pdpData?.product, state.selectedVariantId);
    return deviceWidth < 767 ? (
      <QuickViewCarouselMobile
        imageArray={imagesArray}
        activeVariant={state.activeVariant}
      />
    ) : (
      <QuickViewDesktopImageComponent
        imageArray={imagesArray}
        activeVariant={state.activeVariant}
      />
    );
  };

  function getGstAdjustedStyle() {
    if (isStoreGstExcluded() || isStoreGstIncluded()) return 'mb0px';
    return '';
  }

  const shouldReviewItem = () => {
    if (reviewSettings?.verified_buyer) return verifedBuyer;
    return true;
  };

  const scrollToReviewsSection = () => {
    if (!shouldReviewItem()) return;
    document.getElementById('reviews-section').scrollIntoView();
  };

  const _renderDetailedDescription = (className) =>
    showDetailedDescription ? (
      <DetailedDescription
        className={className}
        detailedDescription={pdpData?.product?.description_detail}
      />
    ) : null;

  function renderOutOfStock() {
    return (
      <Button
        label={'Out Of Stock'}
        backgroundColor={'#C6C6C6'}
        fontSize="18"
        px="12px"
        py="12px"
        mt="24px"
        width="100%"
        roundness={storeInfo?.theme?.roundness}
      />
    );
  }

  function renderNotAcceptingOrderMessage() {
    return (
      <p>
        We are not accepting online orders right now.{' '}
        {!!storePhone && (
          <>
            For queries, call us at{' '}
            <a href={`tel:${storePhone}`} onClick={numberClickFunc}>
              {storePhone}
            </a>
          </>
        )}
      </p>
    );
  }

  const _renderContent = () => {
    return (
      <section className="QuickViewModal">
        <div className="QuickViewModal__main">
          <div className="QuickViewModal__main--image-component">
            {_renderImagesBox()}
            <section className="flex-ns justify-center items-center pv4">
              {pdpData?.product?.available === 0 ||
              (state.activeVariant !== -1 &&
                pdpData?.product?.variants?.[state.activeVariant]?.available === 0) ? (
                isNotifyMeFeatureVisible && isNotifyProductEnabled() ? (
                  <NotifyProduct
                    product={pdpData?.product}
                    activeVariant={{
                      variant_id:
                        state.selectedVariantId !== -1 ? state.selectedVariantId : null,
                    }}
                  />
                ) : (
                  renderOutOfStock()
                )
              ) : deviceWidth <= MAX_MOBILE_WIDTH ? null : (
                _renderAddToCartBuyAgainCta()
              )}
            </section>
            {IS_DESKTOP && (
              <SatisfiedBogoPromos
                type={PROMO_APPLICABILITY_TYPES.PRODUCT}
                productId={pdpData?.product?.id}
                showBottomCTA={true}
                customClasses="tw-max-w-[450px] tw-mx-auto !tw-pt-0"
              />
            )}
          </div>
          <div className="QuickViewModal__main--inner relative">
            <div
              id="reactMagnifyImageId"
              className="w-100 absolute"
              style={{
                top: 0,
                left: 0,
                height: 'fit-content',
              }}
            ></div>
            <div className="flex flex-column">
              <div className="QuickViewModal__main--info-box">
                <h4>{pdpData?.product?.name}</h4>
                {pdpData?.product?.store_item_review_rating?.review_rating &&
                pdpData?.product?.store_item_review_rating?.review_rating?.[0]
                  ?.avg_rating &&
                reviewSettings?.status &&
                isCRRFeatureUnlocked ? (
                  <div className="flex items-center pt10px">
                    <RatingBox
                      isVisible={
                        !!pdpData?.product?.store_item_review_rating?.review_rating?.[0]
                          ?.avg_rating
                      }
                      rating={
                        pdpData?.product?.store_item_review_rating?.review_rating?.[0]
                          ?.avg_rating
                      }
                    />
                    <p className="mv0 f12px fw5 sonic-silver pl8px">
                      {pdpData?.product?.store_item_review_rating?.ratings_count} Ratings
                      & {pdpData?.product?.store_item_review_rating?.reviews_count}{' '}
                      Reviews
                    </p>
                  </div>
                ) : null}
                {deviceWidth > 766 &&
                userData?.isLogin &&
                reviewSettings?.status &&
                isCRRFeatureUnlocked ? (
                  <>
                    <div
                      className="flex items-center pt8px pointer"
                      onClick={scrollToReviewsSection}
                      style={{ opacity: shouldReviewItem() ? 1 : 0.21 }}
                    >
                      <p
                        data-tip
                        data-for="review-this-product"
                        className="fw6 f14px pr8px mv0"
                      >
                        Review this product
                      </p>
                      <img
                        src="/assets/images/right-arrow.svg"
                        width="16px"
                        height="8px"
                      />
                    </div>
                    <ReactTooltip
                      id="review-this-product"
                      place="bottom"
                      effect="solid"
                      multiline={true}
                      padding="8px"
                      disable={shouldReviewItem()}
                    >
                      <p className="mv0 fw5 f12px">
                        Only buyers of this item can submit reviews. If you have any
                        <br />
                        concerns, please share them with us and we would look into it.
                      </p>
                    </ReactTooltip>
                  </>
                ) : null}
              </div>
              <div className={`QuickViewModal__main--price-box ${getGstAdjustedStyle()}`}>
                <span className="price-amount--box">
                  <p
                    className="main-price"
                    style={{
                      color: storeInfo?.theme?.colors.primary_color,
                    }}
                  >
                    ₹{state?.cost?.discounted_price}
                  </p>
                  {state?.cost?.discounted_price !== state?.cost?.price &&
                    state?.cost?.discounted_price !== '' && (
                      <p className="slashed-price">₹{state?.cost?.price}</p>
                    )}
                </span>
                {getDiscountPercentage(
                  state?.cost?.price,
                  state?.cost?.discounted_price
                ) !== 0 && (
                  <span className="price-amount--box">
                    <p className="discounted-percent">
                      {getDiscountPercentage(
                        state?.cost?.price,
                        state?.cost?.discounted_price
                      )}
                      % OFF
                    </p>
                  </span>
                )}
              </div>
              {isStoreGstIncluded() && (
                <article className="QuickViewModal__main--gst-inclusive">
                  Inclusive of all taxes
                </article>
              )}
              {isStoreGstExcluded() && (
                <article className="QuickViewModal__main--gst-exclusive">
                  +{pdpData?.product?.tax?.gst}% GST Charges
                </article>
              )}
              {/* For non variant product */}
              {!isB2bStore &&
                !pdpData?.product?.variants?.length &&
                checkForLimitedQuantity(pdpData?.product) && (
                  <p className="highlighter-red-color mt0 mb2 f12px">
                    {pdpData?.product?.available_quantity
                      ? `Hurry! Only ${pdpData?.product?.available_quantity} units left!`
                      : `Out of stock`}
                  </p>
                )}
              <ProductScheduler
                schedualerConstraints={schedualerConstraints}
                showSchedulerError={showSchedulerError}
                setScheduleInfo={(data) => {
                  setShowSchedulerError(false);
                  setScheduleData(data);
                  changeSchedulingInfoAndSetCart(data, itemIndexInCart);
                }}
                scheduleInfo={scheduleInfo}
                customClasses="!tw-pt-[16px] md:!tw-pt-0 !tw-px-[18px] md:!tw-px-0 md:!tw-max-w-[380px]"
              />
              {/* For variant product */}
              {storeInfo?.services?.store_flag !== 0 &&
                !!pdpData?.product?.variants?.length && (
                  <div className="productDescription__contentBox--variant md:tw-pl-0">
                    <h5>Choose One</h5>
                    <VariantRadioGroup
                      itemLowQty={pdpData?.product?.low_quantity}
                      itemList={pdpData?.product?.variants || []}
                      activeIdx={state.activeVariant}
                      onChange={onVariantSelect}
                    />
                  </div>
                )}
              {!!pdpData?.product?.description && !!storeInfo?.services?.store_flag && (
                <div className="QuickViewModal__main--description-box">
                  {pdpData?.product?.description ? (
                    <>
                      <div className="fw6 f6 mb3">Details</div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: pdpData?.product?.description,
                        }}
                      />
                    </>
                  ) : null}
                  <div className="QuickViewModal__bottom bottom--desktop">
                    {storeInfo?.services?.store_flag === 0 && (
                      <article className="QuickViewModal--store-closed">
                        <span className="QuickViewModal--store-closed-icon">
                          <NoStoreInfoIcon />
                        </span>
                        <span className="QuickViewModal--store-closed-para">
                          {renderNotAcceptingOrderMessage()}
                        </span>
                      </article>
                    )}
                  </div>
                </div>
              )}
              {_renderDetailedDescription('quick-view-mobile-html-description')}
              {reviewSettings?.status &&
              isCRRFeatureUnlocked &&
              deviceWidth <= MAX_MOBILE_WIDTH ? (
                <ReviewsSection
                  shouldReviewItem={shouldReviewItem}
                  product={pdpData?.product}
                  data={pdpData?.product?.store_item_review_rating}
                />
              ) : null}
            </div>
            {isDeliveryPincodeBased() ? (
              <CheckItemDelivery
                mb={'4.5vh'}
                pb={20}
                item_name={pdpData?.product?.name}
                item_id={pdpData?.product?.id}
                itemsPrice={state?.cost?.discounted_price}
              />
            ) : null}
            {!IS_DESKTOP && (
              <SatisfiedBogoPromos
                type={PROMO_APPLICABILITY_TYPES.PRODUCT}
                productId={pdpData?.product?.id}
                showBottomCTA={true}
              />
            )}
            <AllProductPagePromos
              type={PROMO_APPLICABILITY_TYPES.PRODUCT}
              productId={pdpData?.product?.id}
              customClasses="tw-pr-[40px]"
            />
          </div>
        </div>
        <div className="QuickViewModal__bottom">
          {storeInfo?.services?.store_flag === 0 && (
            <article className="QuickViewModal--store-closed">
              <span className="QuickViewModal--store-closed-icon">
                <NoStoreInfoIcon />
              </span>
              <span className="QuickViewModal--store-closed-para">
                {renderNotAcceptingOrderMessage()}
              </span>
            </article>
          )}
          {(pdpData?.product?.available && state.activeVariant === -1) ||
          (state.activeVariant !== -1 &&
            pdpData?.product?.variants[state.activeVariant]?.available)
            ? _renderAddToCartBuyAgainCta()
            : null}
        </div>
        {_renderDetailedDescription('quick-view-desktop-html-description')}
        {reviewSettings?.status &&
        isCRRFeatureUnlocked &&
        deviceWidth > MAX_MOBILE_WIDTH ? (
          <ReviewsSection
            shouldReviewItem={shouldReviewItem}
            product={pdpData?.product}
            data={pdpData?.product?.store_item_review_rating}
          />
        ) : null}
        <QuntityUnavailableAlert />
      </section>
    );
  };

  return pdpData && pdpData?.visible ? (
    deviceWidth < 767 ? (
      renderMobileView(_renderContent())
    ) : (
      <div className="backdrop-container">{renderDesktopView(_renderContent())}</div>
    )
  ) : (
    <></>
  );
};

export default QuickViewModal;
