export function QVCloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 44}
      height={props.size || 44}
      viewBox="0 0 44 44"
    >
      <defs>
        <style>
          {'.prefix__c{fill:none;stroke:#fff;stroke-linecap:round;stroke-width:3px}'}
        </style>
      </defs>
      <g transform="translate(-131.085 -188.085)">
        <path fill="none" d="M131.085 188.085h44v44h-44z" />
        <circle
          cx={21}
          cy={21}
          r={21}
          transform="translate(132.085 189.085)"
          fill="#282829"
        />
      </g>
      <path className="prefix__c" d="M15 15l14 14M29 15L15 29" />
    </svg>
  );
}
