import React, { useEffect, useRef, useState } from 'react';
import { Slider } from '@/components/ReactSlickCarousel';

const QuickViewCarouselMobile = (props) => {
  const { imageArray } = props;
  const sliderRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    setInitialSlide(0);
    sliderRef?.current?.slickGoTo(0);
    stopVideo();
  }, [props.activeVariant]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    activeSlide: initialSlide,
    afterChange: (current) => setActiveSlide(current),
    beforeChange: (current) => {
      if (imageArray[current]?.media_type === 2) {
        stopVideo();
      }
    },
  };

  if (!imageArray || imageArray.length === 0) return null;

  const stopVideo = () => {
    const productVedioIframe = document.querySelector('.mobileQV-image-slider iframe');
    productVedioIframe && (productVedioIframe.src = productVedioIframe.src);
  };

  /**
   * slider prev button
   * click handler function
   */
  const onPrevClick = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef?.current?.slickPrev();
    }
  };

  /**
   * slider next button
   * click handler function
   */
  const onNextClick = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef?.current?.slickNext();
    }
  };

  return (
    <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
      {imageArray?.map((image, index) => (
        <div key={`${index}-image`} className="mobileQV-image-slider">
          {image?.media_type === 2 ? (
            <section className="flex justify-center items-center h-100 relative">
              {imageArray.length > 1 && (
                <>
                  <span
                    className=" absolute left-0 h3 w2 bg-black-20 flex justify-center items-center"
                    onClick={onPrevClick}
                  >
                    <img src="/assets/images/arrowb.png" className="img h1" />
                  </span>
                  <span
                    className=" absolute right-0 h3 w2 bg-black-20 flex justify-center items-center"
                    onClick={onNextClick}
                  >
                    <img src="/assets/images/arrowf.png" className="img h1" />
                  </span>
                </>
              )}
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${image?.image_url?.substr(
                  -11
                )}?autoplay=${activeSlide === index ? 1 : 0}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                auto
              ></iframe>
            </section>
          ) : (
            <img
              src={image.image_url}
              alt={`image ${image.image_id}`}
              style={{ objectFit: 'contain', width: '100%', height: 260 }}
            />
          )}
        </div>
      ))}
    </Slider>
  );
};

const QuickViewDesktopImageComponent = (props) => {
  const { imageArray } = props;
  const sliderRef = useRef();
  // const [activeSlide, setActiveSlide] = useState(0);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    setInitialSlide(0);
    sliderRef?.current?.slickGoTo(0);
  }, [props.activeVariant]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    activeSlide: initialSlide,
    // afterChange: current => setActiveSlide(current),
    beforeChange: (current) => {
      if (imageArray[current]?.media_type === 2) {
        stopVideo();
      }
    },
  };

  const stopVideo = () => {
    const productVedioIframe = document.querySelector('.desktopQV-vedio > iframe');
    productVedioIframe && (productVedioIframe.src = productVedioIframe.src);
  };

  /**
   * slider prev button
   * click handler function
   */
  const onPrevClick = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef?.current?.slickPrev();
    }
  };

  /**
   * slider next button
   * click handler function
   */
  const onNextClick = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef?.current?.slickNext();
    }
  };

  return (
    <Slider ref={sliderRef} {...settings}>
      {imageArray?.map((image, index) => (
        <div key={`${index}-image`}>
          {image?.media_type === 2 ? (
            <section
              className="desktopQV-vedio flex justify-center items-center h-100 relative bg-black"
              style={{ height: '450px' }}
            >
              {imageArray.length > 1 && (
                <>
                  <span
                    className=" absolute left-0 h3 w2 bg-black-20 flex justify-center items-center"
                    onClick={onPrevClick}
                  >
                    <img src="/assets/images/arrowb.png" className="img h1" />
                  </span>
                  <span
                    className=" absolute right-0 h3 w2 bg-black-20 flex justify-center items-center"
                    onClick={onNextClick}
                  >
                    <img src="/assets/images/arrowf.png" className="img h1" />
                  </span>
                </>
              )}
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${image?.image_url?.substr(-11)}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                auto
              ></iframe>
            </section>
          ) : (
            // <span></span>
            <img
              src={image.image_url}
              alt={`image ${image.image_id}`}
              style={{ objectFit: 'contain', width: '100%', height: '450px' }}
            />
          )}
        </div>
      ))}
    </Slider>
  );
};

export { QuickViewCarouselMobile, QuickViewDesktopImageComponent };
